.add-booking
  border: 1px solid #DEDEDE
  box-sizing: border-box
  border-radius: 4px

.edit-booking 
  border: 1px solid #DEDEDE
  box-sizing: border-box
  border-radius: 4px
  margin-top: 15px
  background: #fff
  overflow: hidden
  position: relative
  &:last-child
    margin-bottom: 15px
  .close-button
    font-size: 50px
    line-height: 0.5
    width: 26px
    height: 28px
    position: absolute
    top: 3px
    right: 3px
    color: #b6b6b6
    cursor: pointer
    transition: .3s
    display: block
    overflow: hidden
    &:hover
      transform: rotate(180deg)
