.AddTicket
  position: relative
  background: #fff
  margin-bottom: 25px
  width: 100%
  margin: auto
  .inputCheck
    position: absolute
    right: 10px
    top: 10px
    display: flex
    align-items: center
    cursor: pointer
    input
      width: 22px
      height: 22px
      border: 2px solid #545454
      box-sizing: border-box
      border-radius: 3px
      margin-left: 10px
    span
      font-weight: 600
  .tickettitle
    display: flex
    align-items: center
    margin-top: 10px
    p
      margin: 0
      margin-left: 8px
      font-size: 18px
      font-weight: 500

  .closebutton
    font-size: 50px
    line-height: 0.5
    width: 26px
    height: 28px
    color: #b6b6b6
    cursor: pointer
    transition: .3s
    display: block
    overflow: hidden
    &:hover
      transform: rotate(180deg)
  .pricebox
    display: flex
    align-items: flex-end
    justify-content: flex-end
  .price
    .pricefield
      font-size: 22px
      max-width: 200px
      font-weight: bold
      border: none
      outline: none
      text-align: right
