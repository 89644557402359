.add-trail,.container-form
  position: relative
  background: #fff
  .row
    border: 0px solid #DEDEDE
    box-sizing: border-box
    border-radius: 4px

    .but-box
      display: flex
      justify-content: flex-end
      align-self: flex-end
      button
        align-self: center
  .close-button
    font-size: 50px
    line-height: 0.5
    width: 26px
    height: 28px
    position: absolute
    top: 3px
    right: 3px
    color: #b6b6b6
    cursor: pointer
    transition: .3s
    display: block
    overflow: hidden
    &:hover
      transform: rotate(180deg)
.add-trail
  .add-trail-row
    border: 1px solid #DEDEDE
.edit-box
  border: 1px solid #DEDEDE
  box-sizing: border-box
  border-radius: 4px
  margin-top: 15px
  background: #f1f1f1
  &:last-child
    margin-bottom: 15px
  .container-form
    border: 1px solid #DEDEDE
    box-sizing: border-box
    border-radius: 4px
    margin-top: 15px
    background: #fff
    overflow: hidden
    button
      margin-right: 10px
    &:last-child
      margin-bottom: 15px
    .row
      background: #fff
  .but-box
    button,a
      width: 100% !important
      a
        margin-left: 8px
.no-save
  background: red

.formtrail-enter
  opacity: 0
  transform: translateX(600px)
.formtrail-enter-active
  opacity: 1
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1)
  transform: translateX(0px)
.formtrail-exit
  opacity: 1
  transform: translateX(0px)
.formtrail-exit-active
  opacity: 0
  transition: 0.6s cubic-bezier(0.45, 0, 0.55, 1)
  transform: translateX(-600px)
