@import './normalize'
.pages
  h1
    padding: 15px 0 25px 0
    @media screen and(max-width: 767px)
      padding: 65px 0 25px 0
.input-style
  label
    display: block
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #7B7B7B
    margin-bottom: 5px
  input
    max-width: 270px
    width: 100%
    height: 40px
    padding: 0 8px
    border: 1px solid #DEDEDE
    box-sizing: border-box
    border-radius: 3px
    @media screen and (max-width: 991px)
      max-width: 100%
.inputSelectPhoneCode
  min-width: 100px
  max-width: 100px
.inputSelect,.inputSelectPhoneCode,.input-select
  input
    height: 23px

.input-checkbox
  position: relative
  max-width: 270px
  .select-day
    display: block
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #7B7B7B
    margin-bottom: 5px
  .input-box
    border: 1px solid #DEDEDE
    padding: 5px 3px
    box-sizing: border-box
    border-radius: 3px
    display: flex
    justify-content: space-between
    margin-bottom: 0
    input
      display: none
      position: absolute
      z-index: -1
    label
      height: 28px
      width: 100%
      display: block
      background: #e7e7e7
      border: 1px solid #a5a5a5
      box-sizing: border-box
      border-radius: 3px
      text-align: center
      line-height: 2
      margin: 0 3px
      cursor: pointer
      font-size: 14px
      &:last-child
        margin-right: none
    input:checked + label
      background: #FF0000
      color: #fff

*
  font-family: 'Roboto', sans-serif

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300400500700&display=swap')
